const localDate = new Date().toLocaleDateString().replace(/\//g, '-');

export const exportPaymentCSV = <T extends Record<string, any>>(data: T[], fileName: string = 'export-payment-'+localDate+".csv" ): void => {
    if (!data || data.length === 0) {
        console.warn("No data available for CSV download.");
        return;
    }

    const headers = Object.keys(data[0]);
    const csvRows: string[] = [];

    csvRows.push(headers.join(','));

    data.forEach((row) => {
        const values = headers.map((header) => `"${row[header] ?? ''}"`);
        csvRows.push(values.join(','));
    });

    const csvBlob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(url);
};
