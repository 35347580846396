import React from 'react';
import './spinner.css'; // Optional: Add custom styles for the spinner

const Spinner: React.FC = () => {
  return (
    <div className="spinner">
      <div className="loading-circle"></div>
    </div>
  );
};

export default Spinner;
