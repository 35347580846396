import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_ColumnFiltersState,
  useMaterialReactTable,
} from 'material-react-table';
import keycloak from '../config/keycloakConfig';
import {getSubscriptionByUserId, getSubscriptionUsersByCreatedBy} from '../services/userSubscriptionService';
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import {Button} from "../components/ui/button";
import {ArrowUpFromLine} from "lucide-react";
import {exportPaymentCSV} from "../components/payments/ExportPaymentCSV";

interface Subscription {
  id: number;
  subscriptionName: string;
  gymGroupName: string;
  gymName: string;
  userName: string;
  email: string;
  phone: string;
  endDate: string;
}

const SubscriptionTable = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sortOrders, setSortOrders] = useState<any[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const navigate = useNavigate();

  const fetchSubscriptions = async () => {
    const userId = keycloak.tokenParsed?.sub;
    if (!userId) {
      setError('User ID is not available.');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const data = await getSubscriptionUsersByCreatedBy(
          userId,
          pagination.pageIndex,
          pagination.pageSize,
          sortOrders,
          globalFilter,
          columnFilters,
      );
      // @ts-ignore
      setSubscriptions(data || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching subscriptions:', err);
      setError('Failed to fetch subscriptions.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [pagination.pageIndex, pagination.pageSize, sortOrders, globalFilter, columnFilters]);

  const columns = useMemo<MRT_ColumnDef<Subscription>[]>(() => [
    // {
    //   accessorKey: 'id',
    //   header: 'ID',
    //   size: 50,
    // },
    {
      accessorKey: 'userName',
      header: 'User Name',
      size: 150,
    },
    {
      accessorKey: 'gymGroupName',
      header: 'Gym Group',
      size: 150,
    },
    {
      accessorKey: 'gymName',
      header: 'Branch',
      size: 150,
    },
    {
      accessorKey: 'subscriptionName',
      header: 'Subscription Name',
      size: 150,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      size: 200,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      size: 150,
      Cell: ({ cell }) => (
          <span className="text-blue-500 font-medium">
          {cell.getValue<string>().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
        </span>
      ),
    },
    {
      accessorKey: 'endDate',
      header: 'Expiry Date',
      size: 150,
      Cell: ({ cell }) => (
          <span className="text-green-600 font-medium">
          {dayjs(cell.getValue<string>()).format('MMM DD, YYYY')}
        </span>
      ),
    },
    {
      id: 'actions',
      header: 'Actions',
      size: 150,
      Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
                onClick={() =>
                    navigate('/user-subscription-form', {
                      state: { subscriptionId: row.original.id, subscription: row.original },
                    })
                }
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
            >
              Edit
            </button>
            <button
                onClick={() => console.log('Delete:', row.original.id)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
            >
              Delete
            </button>
          </div>
      ),
    },
  ], [navigate]);

  const handleClick = () => {
    exportPaymentCSV(subscriptions);
  }

  const table = useMaterialReactTable({
    columns,
    data: subscriptions,
    onPaginationChange: setPagination,
    onSortingChange: setSortOrders,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    state: { pagination, sorting: sortOrders, globalFilter },
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: (pagination.pageIndex + 1) * pagination.pageSize + 1,
  });

  return (
      <div>
        <div className="header">
          <h1>Subscription users</h1>
          <Button className="add-gym-button" onClick={handleClick}>
            <ArrowUpFromLine/> Export as CSV
          </Button>
        </div>
        {error && <div className="error-message">{error}</div>}
        <MaterialReactTable table={table}/>
      </div>
  );
};

export default SubscriptionTable;
