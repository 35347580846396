import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './GymCard.css'; // Optional CSS for card styling
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

interface GymBranch {
  id: number;
  images: string[]; // Array to hold multiple images
  name: string;
  description: string;
  location: string;
}

interface GymBranchCardProps {
  gymBranch: GymBranch;
  onDelete: (id: number) => void;
  gymGroupId: number;
}

const GymBranchCard: React.FC<GymBranchCardProps> = ({ gymBranch, onDelete, gymGroupId }) => {
  const navigate = useNavigate();

  // Slider settings for auto-play every 2 seconds
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="bg-white rounded-lg shadow-lg w-[300px] mb-6 p-6 text-center">
      {gymBranch.images.length > 1 ? (
        <Slider {...sliderSettings}>
          {gymBranch.images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`${gymBranch.name} ${index + 1}`} className="gym-image" />
            </div>
          ))}
        </Slider>
      ) : (
        <img
          src={gymBranch.images[0]}
          alt={gymBranch.name}
          className="gym-image"
        />
      )}
      <div className="gym-info">
        <div className="gym-header">
          <h3>{gymBranch.name}</h3>
          <FontAwesomeIcon
            onClick={() => onDelete(gymBranch.id)}
            icon={faTrashAlt}
            className="delete-icon"
          />
        </div>
        <p>{gymBranch.description}</p>
        <p>
          <span role="img" aria-label="location">📍</span> {gymBranch.location}
        </p>
      </div>
      <div className="gym-actions">
        <button
          className="view-btn"
          onClick={() => navigate('/add-gym-subscription', {
            state: { gymId: gymBranch.id, gymName: gymBranch.name }
          })}
        >
          View
        </button>
        <button  onClick={() => navigate('/add-branch', { state: { gymId: gymBranch.id, gymGroupId } })} className="edit-btn">Edit</button>
      </div>
    </div>
  );
};

export default GymBranchCard;
