import React, { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef, MRT_ColumnFiltersState,
    useMaterialReactTable,
} from 'material-react-table';
import keycloak from '../../config/keycloakConfig';
import { getPaymentByCreatedBy } from '../../services/paymentService';
import {Button} from "../ui/button";
import {ArrowUpFromLine} from "lucide-react";
import {exportPaymentCSV} from "./ExportPaymentCSV";
import "./Payment.css";

interface Payment {
    subscriptionUserName: string;
    subscriptionPlanName: string;
    amountPaid: number;
    paymentStatus: string;
    paymentMethod: string;
}
const PaymentTable = () => {
    const [payments, setPayments] = useState<Payment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [sortOrders, setSortOrders] = useState<any[]>([]);  // Default to no sorting
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    // const [rowCount, setRowCount] = useState(0);

    const fetchPayments = async () => {
        const userId = keycloak.tokenParsed?.sub;
        if (!userId) {
            setError('User ID is not available.');
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const data = await getPaymentByCreatedBy(userId, pagination.pageIndex, pagination.pageSize, sortOrders, globalFilter, columnFilters);
            // @ts-ignore
            setPayments(data || []);
            setError(null);
        } catch (err) {
            console.error('Error fetching payments:', err);
            setError('Failed to fetch payments.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPayments().then(r => r);
    }, [pagination.pageIndex, pagination.pageSize, sortOrders, globalFilter, columnFilters]);

    const columns = useMemo<MRT_ColumnDef<Payment>[]>(() => [
        {
            accessorKey: 'subscriptionUserName',
            header: 'User Name',
            size: 200,
            Cell: ({ cell }) => {
                const rawValue = cell.getValue<string>();
                return <div className="capitalize">{rawValue || "N/A"}</div>;
            }
        },
        {
            accessorKey: 'subscriptionPlanName',
            header: 'Subscription Plan',
            size: 200,
            Cell: ({ cell }) => {
                const rawValue = cell.getValue<string>();
                return <div className="capitalize">{rawValue || "N/A"}</div>;
            }
        },
        {
            accessorKey: 'amountPaid',
            header: 'Amount Paid',
            size: 120,
            Cell: ({ cell }) => {
                const amountPaid = cell.getValue<number>();
                const formatted = new Intl.NumberFormat("en-LK", {
                    style: "currency",
                    currency: "SAR",
                }).format(amountPaid);
                return <span className="text-left accent-gray-400">{formatted}</span>;
            },
        },
        {
            accessorKey: 'paymentStatus',
            header: 'Payment Status',
            size: 150,
            Cell: ({ cell }) => {
                const paymentStatus = cell.getValue<string>();
                const statusColors: Record<string, string> = {
                    PENDING: "bg-yellow-100 text-yellow-800 border-yellow-800",
                    SUCCEED: "bg-green-100 text-green-800 border-green-800",
                    FAILED: "bg-red-100 text-red-800 border-red-800",
                };
                return (
                    <span
                        className={`px-3 py-1 rounded-full text-xs font-semibold ${statusColors[paymentStatus] || "bg-gray-100 text-gray-800"}`}
                    >
                        {paymentStatus}
                    </span>
                );
            },
        },
        {
            accessorKey: 'paymentMethod',
            header: 'Payment Method',
            size: 150,
            Cell: ({ cell }) => {
                const rawValue = cell.getValue<string>();
                const formattedValue = rawValue.replace(/_/g, " ");
                return <div className="capitalize">{formattedValue}</div>;
            },
        },
    ], [sortOrders, globalFilter]);

    const table = useMaterialReactTable({
        columns,
        data: payments,
        onPaginationChange: setPagination,
        onSortingChange: setSortOrders,
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        state: { pagination, sorting: sortOrders, globalFilter },
        rowCount: ((pagination.pageIndex + 1) * pagination.pageSize)+1,
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
    });

    const handleClick = () => {
        exportPaymentCSV(payments);
    }

    return (
        <div>
            <div className="header">
                <h1>Payment summary</h1>
                <Button className="add-gym-button" onClick={handleClick}>
                    <ArrowUpFromLine/> Export as CSV
                </Button>
            </div>
            <MaterialReactTable table={table}/>
        </div>
    );
};

export default PaymentTable;
