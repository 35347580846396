import Keycloak from 'keycloak-js';

// Define Keycloak configuration object
const keycloakConfig = {
  url: 'https://kc.mycity.evxtest.monster',
  realm: 'my_city_dev',
  clientId: 'my-city-gym',
};

// Initialize Keycloak instance with the configuration object
const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
