import { postData, getData, deleteData, putData } from './apiService';

interface Subscription {
    subscriptionName: any;
    description: string;
    price: number;
    duration: number;
    createdBy: string;
}

// Interface for the response from createSubscription
interface CreateSubscriptionResponse {
    id: number; // Assuming 'id' is a number; change to 'string' if needed
    // Add other fields if there are any
}

// Function to create a new Subscription
export const createSubscription = async (SubscriptionData: Subscription, gymId: number): Promise<CreateSubscriptionResponse> => {
    try {
        const endpoint = `/api/subscriptions/create/${gymId}`;
        const response = await postData(endpoint, SubscriptionData);
        
        // Cast the response data to the CreateSubscriptionResponse type
        return response.data as CreateSubscriptionResponse;
    } catch (error) {
        console.error('Error creating subscription:', error);
        throw error;
    }
};

// Function to get subscription by gymId
export const getSubscriptionByGymId = async (gymId: number) => {
    try {
        const timestamp = new Date().getTime(); // Generate a unique timestamp
        const endpoint = `/api/subscriptions/get-by-gym-id/${gymId}?_=${timestamp}`; // Construct the endpoint
        const response = await getData(endpoint); // Make the GET request using getData function
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error fetching subscription by gymId:', error);
        throw error; // Throw the error to be handled by the caller
    }
};

// Function to delete a subscription by ID
export const deleteSubscription = async (subscriptionId: number) => {
    try {
        const endpoint = `/api/subscriptions/delete/${subscriptionId}`;
        const response = await deleteData(endpoint);
        return response.data; // Optionally return data or a success message
    } catch (error) {
        console.error('Error deleting subscription:', error);
        throw error;
    }
};

// Function to update an existing Subscription
export const updateSubscription = async (SubscriptionData: Subscription, subscriptionId: number): Promise<CreateSubscriptionResponse> => {
    try {
        const endpoint = `/api/subscriptions/update/${subscriptionId}`; // Adjust the endpoint according to your API
        const response = await putData(endpoint, SubscriptionData); // Use postData for the update

        // Cast the response data to the CreateSubscriptionResponse type
        return response.data as CreateSubscriptionResponse;
    } catch (error) {
        console.error('Error updating subscription:', error);
        throw error;
    }
};
