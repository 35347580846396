import React, { useState, useEffect } from 'react';

interface Hour {
  day: string;
  isOpen: boolean;
  startTime: string;
  endTime: string;
}

interface OpeningHoursProps {
  hours: Hour[];
  onHoursChange: (updatedHours: Hour[]) => void;
}

const OpeningHours: React.FC<OpeningHoursProps> = ({ hours, onHoursChange }) => {
  const [openingHours, setOpeningHours] = useState<Hour[]>([]);

  // Set initial opening hours once on component mount
  useEffect(() => {
    setOpeningHours(
      hours.map((hour) => ({
        ...hour,
        isOpen: hour.isOpen ?? true,
        startTime: hour.startTime || '09:00',
        endTime: hour.endTime || '17:00',
      }))
    );
  }, [hours]);

  // Notify parent component only when openingHours changes due to user interaction
  const handleHoursChange = (updatedHours: Hour[]) => {
    setOpeningHours(updatedHours);
    onHoursChange(updatedHours); // Notify parent with the updated hours
  };

  const toggleOpenStatus = (index: number) => {
    const updatedHours = openingHours.map((hour, idx) =>
      idx === index ? { ...hour, isOpen: !hour.isOpen } : hour
    );
    handleHoursChange(updatedHours);
  };

  const updateTime = (index: number, field: 'startTime' | 'endTime', value: string) => {
    const updatedHours = openingHours.map((hour, idx) =>
      idx === index ? { ...hour, [field]: value } : hour
    );
    handleHoursChange(updatedHours);
  };

  return (
    <div className="mt-4">
      <h2 className="text-lg font-semibold mb-4">Opening Hours</h2>
      {openingHours.map((hour, index) => {
        // Check if the index is even to render two entries per row
        if (index % 2 === 0) {
          return (
            <div key={hour.day} className="flex items-center mb-4">
              {/* Render first item */}
              <div className="flex items-center w-1/2">
                <div className="w-1/4">{hour.day}</div>
                <div className="w-1/4 flex items-center">
                  <input
                    type="checkbox"
                    checked={hour.isOpen}
                    onChange={() => toggleOpenStatus(index)}
                    className="mr-2"
                  />
                  <span>{hour.isOpen ? 'Open' : 'Closed'}</span>
                </div>
                <input
                  type="time"
                  value={hour.startTime}
                  onChange={(e) => updateTime(index, 'startTime', e.target.value)}
                  className={`p-2 border rounded-md w-1/4 mx-2 ${hour.isOpen ? '' : 'opacity-50'}`}
                  disabled={!hour.isOpen}
                />
                <span>-</span>
                <input
                  type="time"
                  value={hour.endTime}
                  onChange={(e) => updateTime(index, 'endTime', e.target.value)}
                  className={`p-2 border rounded-md w-1/4 mx-2 ${hour.isOpen ? '' : 'opacity-50'}`}
                  disabled={!hour.isOpen}
                />
              </div>
  
              {/* Render second item if available */}
              {openingHours[index + 1] && (
                <div className="flex items-center w-1/2 ml-4" key={openingHours[index + 1].day}>
                  <div className="w-1/4">{openingHours[index + 1].day}</div>
                  <div className="w-1/4 flex items-center">
                    <input
                      type="checkbox"
                      checked={openingHours[index + 1].isOpen}
                      onChange={() => toggleOpenStatus(index + 1)}
                      className="mr-2"
                    />
                    <span>{openingHours[index + 1].isOpen ? 'Open' : 'Closed'}</span>
                  </div>
                  <input
                    type="time"
                    value={openingHours[index + 1].startTime}
                    onChange={(e) => updateTime(index + 1, 'startTime', e.target.value)}
                    className={`p-2 border rounded-md w-1/4 mx-2 ${openingHours[index + 1].isOpen ? '' : 'opacity-50'}`}
                    disabled={!openingHours[index + 1].isOpen}
                  />
                  <span>-</span>
                  <input
                    type="time"
                    value={openingHours[index + 1].endTime}
                    onChange={(e) => updateTime(index + 1, 'endTime', e.target.value)}
                    className={`p-2 border rounded-md w-1/4 mx-2 ${openingHours[index + 1].isOpen ? '' : 'opacity-50'}`}
                    disabled={!openingHours[index + 1].isOpen}
                  />
                </div>
              )}
            </div>
          );
        }
        return null; // Skip rendering if index is odd (already paired)
      })}
    </div>
  );
  
};

export default OpeningHours;
