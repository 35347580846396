import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import AddGym from './components/AddGym';
import AddBranch from './components/AddBranch';
import GymForm from './components/GymForm';
import GymBranch from './components/GymBranch';
import './App.css';
import keycloak from './config/keycloakConfig';
import AddSubcription from './components/GymSubscription';
import SubscriptionForm from './components/SubscriptionForm';
import ManageSubscribedUsers from './components/SubscribedUsers';
import UserForm from './components/userSubscriptionForm';
import PaymentTable from "./components/payments/Payment";



// Define types for user roles and keycloak initialization
type KeycloakTokenParsed = {
  realm_access?: { roles: string[] };
  resource_access?: { [key: string]: { roles: string[] } };
};

const App: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<string[]>([]); // State to store user roles
  const keycloakInitialized = useRef<boolean>(false); // Track whether Keycloak has been initialized
  const navigate = useNavigate(); // Initialize the navigate hook

  // Toggle sidebar collapse
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!keycloakInitialized.current) {
      // Initialize Keycloak only once
      keycloak.init({ onLoad: 'login-required' })
        .then((authenticated: boolean) => {
          setIsAuthenticated(authenticated);

          if (authenticated) {
            // Store the Keycloak token in localStorage
            localStorage.setItem('authToken', keycloak.token as string); // Store the token
            
            // Retrieve roles from the token
            const tokenParsed: KeycloakTokenParsed | undefined = keycloak.tokenParsed;
            const roles = tokenParsed?.realm_access?.roles || [];
            setUserRoles(roles); // Store the roles in state

            // Optionally, store the roles in localStorage or sessionStorage
            localStorage.setItem('userRoles', JSON.stringify(roles));

            // Redirect to /add-gym after successful login
            navigate('/add-gym');
          }
        })
        .catch((error) => {
          console.error('Failed to initialize Keycloak:', error);
        });

      keycloakInitialized.current = true; // Mark Keycloak as initialized
    }
  }, [navigate]);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard">
      <Sidebar isCollapsed={isCollapsed} handleToggle={handleToggle} />
      <div className="content">
        <div className={`main-content ${isCollapsed ? 'main-content-collapsed' : ''}`}>
          <Routes>
            <Route path="/add-gym" element={<AddGym />} />
            <Route path="/add-gym-business" element={<GymForm />} />
            <Route path="/add-branch" element={<AddBranch />} />
            <Route path="/gym-branch" element={<GymBranch />} />
            <Route path="/add-gym-subscription" element={<AddSubcription />} />
            <Route path="/subscription-form" element={<SubscriptionForm />} />
            <Route path="/subscription-user" element={<ManageSubscribedUsers/>} />
            <Route path="/user-subscription-form" element={<UserForm/>} />
            <Route path="/payments" element={<PaymentTable/>}/>
            {/*<Route path="/payments" element={<PaymentPage/>}/>*/}
          </Routes>
        </div>
      </div>
    </div>
  );
};

const AppWrapper: React.FC = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
