import React, { useEffect, useState, ChangeEvent, FormEvent, useCallback, useRef } from 'react';
import { useNavigate , useLocation, useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import { getPresignedUrl } from '../services/preSignedUrlService';
import { createGym, updateGym } from '../services/gymBusinessService';
import keycloak from '../config/keycloakConfig'; 
import { saveGymGroupImages,updateGymGroupImages } from '../services/imageUploadService';
import Spinner from './spinner';
import { getGymGroupById } from '../services/gymBusinessService';

interface GymData {
  gymName: string;
  description: string;
  country: string;
  logos: File[]; // Array of File objects
  imageIds?: number[];
}

interface Errors {
  gymName?: string;
  description?: string;
  country?: string;
}

function GymForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const gymId = location.state?.gymId;
  const fileInputRef = useRef<HTMLInputElement>(null); // Use ref instead of querySelector
  

  // Initial state with type GymData
  const [gymData, setGymData] = useState<GymData>({
    gymName: '',
    description: '',
    country: '',
    logos: [],
  });

  type LogoFile = { file: File; id: string };


  const [errors, setErrors] = useState<Errors>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [removedImageIds, setRemovedImageIds] = useState<string[]>([]);
  const originalImageIds = useRef(new Set());
  const [initialLoadTimestamp, setInitialLoadTimestamp] = useState<number>(Date.now());
  
  const validate = useCallback((data: GymData) => {
    let tempErrors: Errors = {};
    if (!data.gymName) tempErrors.gymName = 'Gym Name is required';
    if (!data.country) tempErrors.country = 'Country is required';
    setErrors(tempErrors);
    setIsFormValid(Object.keys(tempErrors).length === 0); // Set form validity
  }, []);
  
  // Updated handleChange to pass the latest state
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const updatedData = { ...gymData, [name]: value };
      setGymData(updatedData);
      validate(updatedData); // Pass the updated state for validation
    },
    [gymData, validate]
  );
  
  // Updated handleFileChange to pass the latest state
  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files ? Array.from(e.target.files) : [];
      const updatedData = {
        ...gymData,
        logos: [...gymData.logos, ...files],
      };
      setGymData(updatedData);
      validate(updatedData); // Pass the updated state for validation
    },
    [gymData, validate]
  );
  
  // Updated handleBlur to pass the latest state
  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
      validate(gymData); // Validate using the current state
    },
    [gymData, validate]
  );
  
  const cleanPresignedUrls = (urls: any): string[] => {
    if (Array.isArray(urls)) {
      // If urls is an array, map over each item and remove 'data:' prefix if present
      return urls.map(url => (typeof url === 'string' && url.startsWith('data:')) ? url.replace('data:', '') : url);
    } else if (typeof urls === 'string') {
      // Split the single string into an array by whitespace (space, newline, etc.)
      const urlArray = urls.split(/\s+/);
      // Remove 'data:' prefix if present
      return urlArray.map(url => url.startsWith('data:') ? url.replace('data:', '') : url);
    } else {
      console.error('Expected a string or an array but received:', urls);
      return [];
    }
  };
  
// Function to upload images to the server
const uploadImages = async (files: File[], presignedUrls: string[]) => {
  // Filter out files without an extension
  const filteredFiles = files.filter((file) => {
    const fileParts = file.name.split('.');
    return fileParts.length > 1; // Only keep files that have at least one dot, indicating an extension
  });

  // Prepare promises for uploading only the filtered files
  const uploadPromises = filteredFiles.map((file, index) => {
    return fetch(presignedUrls[index], {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type, // Set the Content-Type header to the file's MIME type
      },
    });
  });

  try {
    const responses = await Promise.all(uploadPromises);

    // Check if all uploads were successful
    responses.forEach((response) => {
      if (!response.ok) {
        throw new Error('Failed to upload one or more images');
      }
    });

    // Return the URLs of the uploaded images (adjust if necessary)
    return presignedUrls.slice(0, filteredFiles.length);
  } catch (error) {
    let errorMessage = 'An unknown error occurred';

    if (error instanceof Error) {
      errorMessage = error.message;
    }

    Swal.fire({
      icon: 'error',
      title: 'Image Upload Failed',
      text: `${errorMessage}. Please try again.`,
    });

    console.error('Detailed error:', error); // Log the error for further debugging

    return [];
  }
};


const urlToFile = async (url: string, fileName: string): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

useEffect(() => {
  if (gymId) {
    setLoading(true);
    getGymGroupById(Number(gymId))
      .then(async (gymGroup) => {
        if (gymGroup) {
          // Filter the images to include only those that start with 'https://'
          const validImages = gymGroup.images
            ?.filter((img) => img.imageUrl.startsWith('https://')) 
            .map((img) => ({
              id: img.id, // Retaining the id
              imageUrl: img.imageUrl
            })) || [];

          // Store original image IDs
          validImages.forEach(img => originalImageIds.current.add(img.id));
          
          const logoFiles = await Promise.all(
            validImages.map((img) => urlToFile(img.imageUrl, 'logo'))
          );
          
          const imageIds = validImages.map(img => img.id); // Store IDs of valid images

          setGymData({
            gymName: gymGroup.gymGroupName || '',
            description: gymGroup.description || '',
            country: gymGroup.country || '',
            logos: logoFiles, // Array of File objects for valid images
            imageIds, // Store IDs of valid images
          });
          
          setInitialLoadTimestamp(Date.now());
          // Log the imageIds after setting gymData
          console.log('Image IDs:', imageIds);
        } else {
          console.warn('Gym group is undefined.');
        }
      })
      .catch((error) => {
        console.error('Error fetching gym data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error fetching gym details',
          text: 'Failed to fetch gym details. Please try again later.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
}, [gymId]);

const handleSubmit = useCallback(
  async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }
    if (gymData.logos.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Please upload images',
        text: 'You need to upload at least one image to proceed.',
      });
      return;
    }
    setLoading(true);
    try {
      const userId = keycloak.tokenParsed?.sub;
      if (!userId) {
        Swal.fire({
          icon: 'error',
          title: 'User ID not found',
          text: 'Unable to retrieve user information. Please try again.',
        });
        setLoading(false);
        return;
      }

      const updatedGymData = {
        ...gymData,
        createdBy: userId,
      };

      let response;
      if (gymId) {
          // Processing gymData.logos to get extensions for non-logo files
          const newImageExtensions = gymData.logos
          .filter((file) => {
            // Log each file's timestamp and name for debugging
            console.log(`File name: ${file.name}, lastModified: ${file.lastModified}, initialLoadTimestamp: ${initialLoadTimestamp}`);
        
            const fileParts = file.name.split('.');
            const hasExtension = fileParts.length > 1; // Check if there's an extension
            const isNotLogoWithoutExtension = fileParts.length > 1 || fileParts[0].toLowerCase() !== 'logo'; // Exclude "logo" without extension
        
            return isNotLogoWithoutExtension;
          })
          .map((file) => {
            const fileParts = file.name.split('.');
            return fileParts[fileParts.length - 1].toLowerCase(); // Get the extension
          });

          let cleanedPresignedUrls: string[] = [];

          if (newImageExtensions.length > 0) {
            console.log('New image extensions (excluding logos):', newImageExtensions);
          
            const gymName = gymData.gymName;
            const presignedUrls: string[] = await getPresignedUrl(gymName, newImageExtensions);
            cleanedPresignedUrls = cleanPresignedUrls(presignedUrls);
          
            if (!presignedUrls || presignedUrls.length === 0) {
              Swal.fire({
                icon: 'error',
                title: 'Failed to get presigned URLs',
                text: 'Please try again later.',
              });
              setLoading(false);
              return;
            }
          
            const uploadedImageUrls = await uploadImages(gymData.logos, cleanedPresignedUrls);
            if (uploadedImageUrls.length === 0) {
              setLoading(false);
              return;
            }
          }
          
        

        response = await updateGym(
          gymId,
          {
            name: updatedGymData.gymName,
            description: updatedGymData.description,
            country: updatedGymData.country,
            createdBy: updatedGymData.createdBy,
          }
        );

        if (response && response.id) {
          // Call the updateGymGroupImages function
          const gymGroupImageUpdate = {
            gymGroupImageDTO: {
              gymGroupId: gymId,
              imageUrls: cleanedPresignedUrls,
              createdBy: updatedGymData.createdBy,
              updatedBy: updatedGymData.createdBy,
            },
             imagesIdList: removedImageIds.map(id => Number(id)), // Provide a list of image IDs if necessary
          };

          await updateGymGroupImages(gymGroupImageUpdate);

          Swal.fire({
            icon: 'success',
            title: 'Gym updated successfully',
            text: 'The gym has been successfully updated.',
          });

          navigate('/add-gym');
        }
      } else {
        const extensions = gymData.logos.map((file) => {
          const fileParts = file.name.split('.');
          return fileParts[fileParts.length - 1].toLowerCase();
        });
  
        const gymName = gymData.gymName;
        const presignedUrls: string[] = await getPresignedUrl(gymName, extensions);
        const cleanedPresignedUrls = cleanPresignedUrls(presignedUrls);
  
        if (!presignedUrls || presignedUrls.length === 0) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to get presigned URLs',
            text: 'Please try again later.',
          });
          setLoading(false);
          return;
        }
  
        const uploadedImageUrls = await uploadImages(gymData.logos, cleanedPresignedUrls);
        if (uploadedImageUrls.length === 0) {
          setLoading(false);
          return;
        }
        response = await createGym(
          {
            name: updatedGymData.gymName,
            description: updatedGymData.description,
            country: updatedGymData.country,
            createdBy: updatedGymData.createdBy,
          },
          updatedGymData.createdBy
        );

        if (response && response.id) {
          const gymGroupImages = {
            gymGroupId: response.id,
            imageUrls: cleanedPresignedUrls,
            createdBy: updatedGymData.createdBy,
            updatedBy: "",
          };

          await saveGymGroupImages(gymGroupImages);

          Swal.fire({
            icon: 'success',
            title: 'Gym created successfully',
            text: 'Your gym has been successfully created.',
          });

          navigate('/add-gym');
        }
      }

      setGymData({
        gymName: '',
        description: '',
        country: '',
        logos: [],
      });

      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: gymId ? 'Error updating gym' : 'Error creating gym',
        text: 'An error occurred while processing your request. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  },
  [isFormValid, gymData, gymId]
);



  const handleCancel = useCallback(() => {
    navigate('/add-gym'); // Navigate to another page
  }, [navigate]);

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedLogos = gymData.logos.filter((_, i) => i !== index);
  
      if (gymId && gymData.imageIds && gymData.imageIds[index]) {
        const removedId = String(gymData.imageIds[index]); // Convert to string
        setRemovedImageIds((prevIds) => [...prevIds, removedId]); // Add to removedImageIds
      }
  
      setGymData((prevData) => ({ ...prevData, logos: updatedLogos }));
  
      if (updatedLogos.length === 0) {
        closeModal();
      }
    },
    [gymData, gymId, setGymData]
  );
  
  

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < gymData.logos.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div className="container mx-auto p-10">
      <form className="bg-white p-8 rounded-lg shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-6">ADD NEW GYM BUSINESS</h2>

        {success && <div className="bg-green-500 text-white p-3 rounded mb-4">SUCCESSFULLY SAVED</div>}

        {/* Gym Name */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">Gym Name<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="gymName"
            value={gymData.gymName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`p-2 border ${errors.gymName && touched.gymName ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
            placeholder="Enter Gym Name"
            required
          />
          {errors.gymName && touched.gymName && <span className="text-red-500 text-sm">{errors.gymName}</span>}
        </div>

        {/* Description */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">Description</label>
          <input
            type="text"
            name="description"
            value={gymData.description}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`p-2 border ${errors.description && touched.description ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
            placeholder="Description"
          />
        </div>

        {/* Country */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">Country<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="country"
            value={gymData.country}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`p-2 border ${errors.country && touched.country ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
            placeholder="Enter Country"
            required
          />
          {errors.country && touched.country && <span className="text-red-500 text-sm">{errors.country}</span>}
        </div>

        {/* Logos Upload */}
        <div className="flex flex-col mb-6">
          <label className="mb-2 font-medium">Images<span className="text-red-500">*</span></label>
          <div className="border border-dashed border-gray-300 p-4 rounded-lg text-center relative">
            <input 
              ref={fileInputRef}
              type="file" 
              name="logos" 
              onChange={handleFileChange} 
              className="hidden" 
              multiple
            />
            <span 
              className="text-gray-500 cursor-pointer"
              onClick={() => fileInputRef.current?.click()}
            >
              Click to upload images
            </span>
            {/* Display the uploaded images */}
            <div className="relative mt-4 grid grid-cols-6 gap-2">
              {gymData.logos.slice(0, 6).map((file, index) => (
                <div key={index} className="relative">
                  <img 
                    src={URL.createObjectURL(file)} 
                    alt={file.name} 
                    className="w-24 h-24 object-cover rounded-lg" 
                  />
                  {/* Close button */}
                  <button
                    type="button"
                    aria-label="Remove image"
                    className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-red-500 text-white rounded-full p-1"
                    onClick={() => handleRemoveImage(index)}
                  >
                    ✕
                  </button>
                </div>
              ))}

              {/* Show "+ more" count if logos exceed 6 */}
              {gymData.logos.length > 6 && (
                <div 
                  className="absolute bottom-2 right-2 bg-gray-700 text-white rounded-full px-3 py-1 text-xs cursor-pointer"
                  onClick={openModal}
                >
                  +{gymData.logos.length - 6} more
                </div>
              )}
            </div>
          </div>
        </div>

       {loading && (
      <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-400">
        <Spinner />
      </div>
    )}

        {/* Buttons Section */}
        <div className="flex justify-start space-x-4">
          <button 
          type="submit" 
          className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
          disabled={!isFormValid}
        >
          {gymId ? 'Update' : 'Submit'}
        </button>


          <button 
            type="button" 
            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600" 
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>

{/* Modal for viewing all images */}
          {/* Modal */}
          {showModal && (
     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg relative">
      {/* Close Button */}
      <button
        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 z-50"
        onClick={closeModal}
      >
        ✕
      </button>

      {/* Image with Delete Button Positioned Below the Center */}
      <div className="relative flex flex-col items-center">
        <img
          src={URL.createObjectURL(gymData.logos[currentImageIndex])}
          alt={`Image ${currentImageIndex + 1}`}
          className="max-w-xs max-h-80 object-cover"
        />

        {/* Delete Button Positioned Outside the Image */}
        <button
          className="bg-gray-700 bg-opacity-50 rounded-full p-2 text-white mt-2"
          onClick={() => handleRemoveImage(currentImageIndex)}
        >
          🗑️
        </button>
      </div>

      <div className="flex justify-between items-center mt-4">
        {/* Previous Button */}
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
          Previous
        </button>

        {/* Image Count Display */}
        <span className="text-gray-700 font-medium">
          {currentImageIndex + 1} of {gymData.logos.length}
        </span>

        {/* Next Button */}
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          onClick={handleNextImage}
          disabled={currentImageIndex === gymData.logos.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default GymForm;
