import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// TypeScript expects an element, so we explicitly type the root element
const rootElement = document.getElementById('root') as HTMLElement;

// Create a root for the React app
const root = ReactDOM.createRoot(rootElement);

// Render the app inside React.StrictMode
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Measure performance
reportWebVitals();
