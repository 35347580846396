// src/services/gymService.ts
import { postData, getData, deleteData,putData } from './apiService';

// Interface for Gym
interface Gym {
  name: string;
  description: string;
  country: string;
  createdBy: string;
}

interface GymBranch {
  id: number;
  image: string;
  name: string;
  description: string;
  location: string;
}

interface Image {
  id: number;
  imageUrl: string;
  gymGroupId: number;
  gymId: number | null;
  createdBy: string;
  updatedBy: string | null;
  createdAt: [number, number, number, number, number, number, number];
  updatedAt: string | null;
}

interface GymBusiness {
  id: number;
  gymGroupName: string;
  description: string;
  country: string | null;
  createdBy: string;
  updatedBy: string | null;
  createdAt: [number, number, number, number, number, number, number];
  updatedAt: string | null;
  userId: string;
  gyms: Array<{
    id: number;
    gymName: string;
    gymType: string;
    accountHolderName: string;
    accountNumber: string;
    bankName: string;
    branchBankName: string;
    swiftCode: string;
    createdBy: string;
    updatedBy: string | null;
    createdAt: [number, number, number, number, number, number, number];
    updatedAt: string | null;
    gymHours: string | null;
    images: Image[] | null;
    subscriptions: any; // Update as needed
    geoLocation: string;
    gymGroupId: number;
  }>;
  images: Image[];
}


// Interface for the response from createGym
interface CreateGymResponse {
  id: number; // Assuming 'id' is a number; change to 'string' if needed
  // Add other fields if there are any
}

interface UpdateGymResponse {
  id: number; // Assuming 'id' is a number; change to 'string' if needed
  // Add other fields if there are any
}

// Interface for Gym Group Images
interface GymGroupImages {
  gymGroupId: number;
  imageUrls: string[];
  createdBy: string;
  updatedBy: string;
}

// Function to create a new Gym
export const createGym = async (gymData: Gym, userId: string): Promise<CreateGymResponse> => {
  try {
    const endpoint = `/api/gym-groups/create/${userId}`;
    const response = await postData(endpoint, gymData);
    
    // Cast the response data to the CreateGymResponse type
    return response.data as CreateGymResponse;
  } catch (error) {
    console.error('Error creating gym:', error);
    throw error;
  }
};

export const updateGym = async (gymGroupId: string, gymData: Gym): Promise<UpdateGymResponse> => {
  try {
    const endpoint = `/api/gym-groups/update/${gymGroupId}`;
    const response = await putData(endpoint, gymData);
    
    // Cast the response data to the UpdateGymResponse type
    return response.data as UpdateGymResponse;
  } catch (error) {
    console.error('Error updating gym group:', error);
    throw error;
  }
};


// Function to save gym group images
export const saveGymGroupImages = async (gymGroupImages: GymGroupImages) => {
  try {
    const endpoint = '/api/image/gym-group/images';
    const response = await postData(endpoint, gymGroupImages);
    return response.data;
  } catch (error) {
    console.error('Error saving gym group images:', error);
    throw error;
  }
};

// Function to get gym groups by userId
export const getGymGroupsByUserId = async (userId: string) => {
  try {
    const timestamp = new Date().getTime(); // Generate a unique timestamp
    const endpoint = `/api/gym-groups/user/${userId}?_=${timestamp}`; // Construct the endpoint with userId
    const response = await getData(endpoint); // Make the GET request using getData function
    return response.data; // Return the data from the response
  } catch (error) {
    console.error('Error fetching gym groups by userId:', error);
    throw error; // Throw the error to be handled by the caller
  }
};

// Update the return type to use GymBranch instead of Gym
export const getGymById = async (businessId: number): Promise<GymBranch[]> => {
  try {
    const timestamp = new Date().getTime(); // Generate a unique timestamp
    const endpoint = `/api/gym-groups/${businessId}?_=${timestamp}`; // Construct the endpoint with businessId
    const response = await getData(endpoint); // Make the GET request using getData function
    return response.data as GymBranch[]; // Assert the type of the response data
  } catch (error) {
    console.error('Error fetching gym branches by businessId:', error);
    throw error; // Throw the error to be handled by the caller
  }
};

export const getGymGroupById = async (businessId: number): Promise<GymBusiness> => {
  try {
    const timestamp = new Date().getTime(); // Generate a unique timestamp
    const endpoint = `/api/gym-groups/${businessId}?_=${timestamp}`; // Construct the endpoint with businessId
    const response = await getData(endpoint); // Make the GET request using getData function
    return response.data as GymBusiness; // Assert the type of the response data as GymBusiness
  } catch (error) {
    console.error('Error fetching gym branches by businessId:', error);
    throw error; // Throw the error to be handled by the caller
  }
};



// Function to delete a gym by ID
export const deleteGymById = async (gymId: number): Promise<void> => {
  try {
    const endpoint = `/api/gym-groups/delete/${gymId}`; // Construct the endpoint with gymId
    await deleteData(endpoint); // Make the DELETE request using deleteData function
    console.log(`Gym with ID ${gymId} deleted successfully.`); // Log success message
  } catch (error) {
    console.error(`Error deleting gym with ID ${gymId}:`, error);
    throw error; // Throw the error to be handled by the caller
  }
};

