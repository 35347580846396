import { postData, getData } from './apiService';

// Interface for gym group images
interface GymGroupImages {
    gymGroupId: number;
    imageUrls: string[];
    createdBy : string;
    updatedBy: string;
  }

  // Interface for gym group image update
interface GymGroupImageUpdate {
    gymGroupImageDTO: {
      gymGroupId: number;
      imageUrls: string[];
      createdBy: string;
      updatedBy: string;
    };
    imagesIdList: number[];
  }

  interface GymBranchImageUpdate {
    gymImageDTO: {
      gymGroupId: number;
      gymId: number;
      imageUrls: string[];
      createdBy: string;
      updatedBy: string;
    };
    imagesIdList: number[];
  }

  // Function to update gym group images
export const updateGymGroupImages = async (gymGroupImageUpdate: GymGroupImageUpdate) => {
    try {
      const endpoint = '/api/image/gym-group/images-update'; // Update endpoint URL as shown in Swagger
      const response = await postData(endpoint, gymGroupImageUpdate); // Make the POST request
      return response.data; // Return the response data
    } catch (error) {
      console.error('Error updating gym group images:', error); // Log any errors
      throw error; // Throw the error to be handled by the caller
    }
  };

  export const updateGymBranchImages = async (gymBranchImageUpdate: GymBranchImageUpdate) => {
    try {
      const endpoint = '/api/image/gym/images-update'; // Update endpoint URL as shown in Swagger
      const response = await postData(endpoint, gymBranchImageUpdate); // Make the POST request
      return response.data; // Return the response data
    } catch (error) {
      console.error('Error updating gym group images:', error); // Log any errors
      throw error; // Throw the error to be handled by the caller
    }
  };

  // New function to save gym group images
export const saveGymGroupImages = async (gymGroupImages: GymGroupImages) => {
    try {
      const endpoint = '/api/image/gym-group/images';
      const response = await postData(endpoint, gymGroupImages);
      return response.data;
    } catch (error) {
      console.error('Error saving gym group images:', error);
      throw error;
    }
  };

    // New function to save gym group images
export const saveGymBranchImages = async (gymBranchImages: GymGroupImages) => {
    try {
      const endpoint = '/api/image/gym/images';
      const response = await postData(endpoint, gymBranchImages);
      return response.data;
    } catch (error) {
      console.error('Error saving gym group images:', error);
      throw error;
    }
  };

// Function to get gym group images by gymGroupId
export const getGymGroupsImageById = async (gymGroupId: number) => {
    try {
      const timestamp = new Date().getTime(); // Generate a unique timestamp
      const endpoint = `/api/image/gym-group/${gymGroupId}/images?_=${timestamp}`; // Construct the endpoint with gymGroupId and timestamp
      const response = await getData(endpoint); // Make the GET request using getData function
      return response.data as string[];
    } catch (error) {
      console.error('Error fetching gym group images by gymGroupId:', error);
      throw error; // Throw the error to be handled by the caller
    }
  };

// Function to get gym branch images by Id
export const getGymBranchImageById = async (BranchId: number): Promise<string[]> => {
    try {
        const timestamp = new Date().getTime(); // Generate a unique timestamp
        const endpoint = `/api/image/gym/${BranchId}/images?_=${timestamp}`; // Construct the endpoint with gymGroupId and timestamp
        const response = await getData(endpoint); // Make the GET request using getData function

        // Assuming response.data is a string with multiple JSON objects concatenated, prefixed by "data:"
        const rawData = response.data as string;
        
        // Split the response string by "data:" to isolate each JSON object
        const jsonStrings = rawData.split(/data:/).filter(Boolean); // Filter out any empty strings
        
        // Parse each JSON string, extract the imageUrl if present, and collect in an array
        const imageUrls: string[] = jsonStrings.map((jsonString) => {
            try {
                const parsedData = JSON.parse(jsonString.trim()); // Parse the trimmed JSON string
                return parsedData.imageUrl || ''; // Extract the imageUrl if it exists
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return ''; // Return an empty string if parsing fails
            }
        }).filter(url => url); // Filter out any empty or undefined URLs
        
        return imageUrls; // Return the array of image URLs
    } catch (error) {
        console.error('Error fetching gym group images by gymGroupId:', error);
        throw error; // Throw the error to be handled by the caller
    }
};

// Function to get gym branch images by Id
export const getBranchImageById = async (BranchId: number): Promise<{ id: number; url: string }[]> => {
  try {
      const timestamp = new Date().getTime(); // Generate a unique timestamp
      const endpoint = `/api/image/gym/${BranchId}/images?_=${timestamp}`; // Construct the endpoint with gymGroupId and timestamp
      const response = await getData(endpoint); // Make the GET request using getData function

      // Assuming response.data is a string with multiple JSON objects concatenated, prefixed by "data:"
      const rawData = response.data as string;
      
      // Split the response string by "data:" to isolate each JSON object
      const jsonStrings = rawData.split(/data:/).filter(Boolean); // Filter out any empty strings
      
      // Parse each JSON string, extract the id and imageUrl if present, and collect in an array
      const images = jsonStrings.map((jsonString) => {
          try {
              const parsedData = JSON.parse(jsonString.trim()); // Parse the trimmed JSON string
              return {
                  id: parsedData.id, // Extract the id
                  url: parsedData.imageUrl || '' // Extract the imageUrl
              };
          } catch (error) {
              console.error('Error parsing JSON:', error);
              return null; // Return null if parsing fails
          }
      }).filter(image => image && image.url); // Filter out any empty or undefined URLs and null objects
      
      return images as { id: number; url: string }[]; // Return the array of image objects
  } catch (error) {
      console.error('Error fetching gym group images by gymGroupId:', error);
      throw error; // Throw the error to be handled by the caller
  }
};


// Function to get gym branch images by Id
export const getGymBranchImageByIdForEdit = async (BranchId: number): Promise<{ url: string }[]> => {
  try {
      const timestamp = new Date().getTime(); // Generate a unique timestamp
      const endpoint = `/api/image/gym/${BranchId}/images?_=${timestamp}`; // Construct the endpoint with gymGroupId and timestamp
      const response = await getData(endpoint); // Make the GET request using getData function

      // Assuming response.data is a string with multiple JSON objects concatenated, prefixed by "data:"
      const rawData = response.data as string;
      
      // Split the response string by "data:" to isolate each JSON object
      const jsonStrings = rawData.split(/data:/).filter(Boolean); // Filter out any empty strings
      
      // Parse each JSON string, extract the imageUrl if present, and collect in an array
      const imageUrls: { url: string }[] = jsonStrings.map((jsonString) => {
          try {
              const parsedData = JSON.parse(jsonString.trim()); // Parse the trimmed JSON string
              return { url: parsedData.imageUrl || '' }; // Return object with `url` property
          } catch (error) {
              console.error('Error parsing JSON:', error);
              return { url: '' }; // Return empty URL if parsing fails
          }
      }).filter(item => item.url); // Filter out any objects with an empty `url`
      
      return imageUrls; // Return the array of objects with `url` property
  } catch (error) {
      console.error('Error fetching gym group images by gymGroupId:', error);
      throw error; // Throw the error to be handled by the caller
  }
};


// Function to get gym group images by Id
export const getGymGroupImageById = async (gymGroupId: number): Promise<string[]> => {
  try {
      const timestamp = new Date().getTime(); // Generate a unique timestamp
      const endpoint = `/api/image/gym-group/${gymGroupId}/images?_=${timestamp}`; // Construct the endpoint with gymGroupId and timestamp
      const response = await getData(endpoint); // Make the GET request using getData function

      // Assuming response.data is a string with multiple JSON objects concatenated, prefixed by "data:"
      const rawData = response.data as string;
      
      // Split the response string by "data:" to isolate each JSON object
      const jsonStrings = rawData.split(/data:/).filter(Boolean); // Filter out any empty strings
      
      // Parse each JSON string, extract the imageUrl if present, and collect in an array
      const imageUrls: string[] = jsonStrings.map((jsonString) => {
          try {
              const parsedData = JSON.parse(jsonString.trim()); // Parse the trimmed JSON string
              return parsedData.imageUrl || ''; // Extract the imageUrl if it exists
          } catch (error) {
              console.error('Error parsing JSON:', error);
              return ''; // Return an empty string if parsing fails
          }
      }).filter(url => url); // Filter out any empty or undefined URLs
      
      return imageUrls; // Return the array of image URLs
  } catch (error) {
      console.error('Error fetching gym group images by gymGroupId:', error);
      throw error; // Throw the error to be handled by the caller
  }
};

  