import { getData } from './apiService';

// Interface for gym group data
interface GymGroup {
  id: number;
  gymGroupName: string;
  description: string;
  country: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: number[];
  updatedAt: number[] | null;
  userId: string;
  gyms: any[] | null;
  images: any[] | null;
}

// Interface for gym data
interface Gym {
  id: number;
  gymName: string;
  gymType: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  branchBankName: string;
  swiftCode: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: number[];
  updatedAt: number[] | null;
  gymHours: GymHour[] | null;
  images: any[] | null;
  subscriptions: any[] | null;
  geoLocation: string;
  gymGroupId: number;
}

// Interface for gym hours data
interface GymHour {
  id: number;
  day: string;
  openTime: number[];
  closeTime: number[];
  gymId: number;
  createdBy: string;
  updatedBy: string | null;
  createdAt: number[];
  updatedAt: number[] | null;
  offDay: boolean;
}


interface GymData {
  gymGroup: GymGroup;
  gyms: Gym[];
  gymHours: GymHour[];
  subscriptionPlans: { id: number; gymId:number; subscriptionName: string; description: string; price: number; duration: number; }[];
}


// Function to get master data by userId
export const getMasterDataByUserId = async (userId: string): Promise<GymData[]> => {
  try {
    const timestamp = new Date().getTime(); // Generate a unique timestamp
    const endpoint = `/api/master-data/${userId}?_=${timestamp}`; // Construct the endpoint with userId
    const response = await getData(endpoint); // Make the GET request using getData function
    return response.data as GymData[]; // Return the data typed as GymData[]
  } catch (error) {
    console.error('Error fetching gym groups by userId:', error);
    throw error; // Throw the error to be handled by the caller
  }
};