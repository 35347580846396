import { axiosInstance } from './apiService';

// Function to generate pre-signed URLs
export const getPresignedUrl = async (gymName: string, extensions: string[]): Promise<string[]> => {
  try {
    // Prepare the endpoint
    const endpoint = `api/image-upload/gym-group/${gymName}/presigned-urls`;

    // Fetch the token from localStorage
    const token = localStorage.getItem('authToken');

    // Make a POST request to the endpoint
    const response = await axiosInstance.post(endpoint, null, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '', // Set the Authorization header conditionally
      },
      params: {
        extensions: extensions.join(','), // Sending extensions as a comma-separated string
      },
    });

    // Return the data from the response
    return response.data; // Adjust according to your API response structure
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
    throw error; // Rethrow error for further handling
  }
};

export const getPresignedUrlforBranch = async (gymGroupId:number,branchName: string, extensions: string[]): Promise<string[]> => {
  try {
    // Prepare the endpoint
    const endpoint = `api/image-upload/gym/${gymGroupId}/${branchName}/presigned-urls`;

    // Fetch the token from localStorage
    const token = localStorage.getItem('authToken');

    // Make a POST request to the endpoint
    const response = await axiosInstance.post(endpoint, null, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '', // Set the Authorization header conditionally
      },
      params: {
        extensions: extensions.join(','), // Sending extensions as a comma-separated string
      },
    });

    // Return the data from the response
    return response.data; // Adjust according to your API response structure
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
    throw error; // Rethrow error for further handling
  }
};

