import { postData, getData, deleteData, putData } from './apiService';

interface GymHoursCreateDTO {
    day: string;
    openTime: string;
    closeTime: string;
    createdBy: string;
    updatedBy: string;
    offDay: boolean;
  }

  interface GymHour {
    day: string;
    offDay: boolean;
    openTime: [number, number];
    closeTime: [number, number];
  }
  

  interface GymDetails {
    gymName: string;
    gymType: string;
    accountHolderName: string;
    accountNumber: string;
    bankName: string;
    branchBankName: string;
    swiftCode: string;
    location: string;
    latitude: number;
    longitude: number;
    createdBy: string;
    gymHoursCreateDTOList: GymHoursCreateDTO[];
    gymHours:  GymHour[];
    geoLocation: string;
    // Add any additional fields returned in the API response
}


interface GymBranch {
    gymName: string;
    gymType: string;
    accountHolderName: string;
    accountNumber: string;
    bankName: string;
    branchBankName: string;
    swiftCode: string;
    location: string;
    latitude: number; // Geolocation latitude
    longitude: number; // Geolocation longitude
    createdBy: string;
    gymHoursCreateDTOList: GymHoursCreateDTO[];
  }
  

interface CreateGymBranchResponse {
    id: number; // Assuming 'id' is a number; change to 'string' if needed
    // Add other fields if there are any
  }

  interface UpdateGymBranchResponse {
    id: number; // Assuming 'id' is a number; change to 'string' if needed
    // Add other fields if there are any
  }  

  // Interface for Gym Group Images
interface GymGroupImages {
    gymGroupId: number;
    imageUrls: string[];
    createdBy: string;
    updatedBy: string;
  }

// Function to create a new Gym
export const createGymBranch = async (formData: GymBranch, gymGroupId: number): Promise<CreateGymBranchResponse> => {
    try {
      const endpoint = `/api/gym/create/${gymGroupId}`;
      const response = await postData(endpoint, formData);
      
      // Cast the response data to the CreateGymBranchResponse type
      return response.data as CreateGymBranchResponse;
    } catch (error) {
      console.error('Error creating gym:', error);
      throw error;
    }
  };

  export const updateGymBranch = async (gymId: number,formData: GymBranch): Promise<UpdateGymBranchResponse> => {
    try {
      const endpoint = `/api/gym/update/${gymId}`;
      const response = await putData(endpoint, formData);
      
      // Cast the response data to the UpdateGymResponse type
      return response.data as UpdateGymBranchResponse;
    } catch (error) {
      console.error('Error updating gym group:', error);
      throw error;
    }
  };

// Function to get gym details by ID
export const getGymById = async (gymId: number): Promise<GymDetails> => {
  try {
      const endpoint = `/api/gym/${gymId}`; // Construct the endpoint with gymId
      const response = await getData(endpoint); // Make the GET request using getData function
      console.log(`Gym details for ID ${gymId}:`, response.data); // Log the gym details

      // Cast response data to GymDetails type
      return response.data as GymDetails;
  } catch (error) {
      console.error(`Error fetching gym details for ID ${gymId}:`, error);
      throw error; // Throw the error to be handled by the caller
  }
};

  
  
  // Function to save gym group images
  export const saveGymGroupImages = async (gymGroupImages: GymGroupImages) => {
    try {
      const endpoint = '/api/image/gym-group/images';
      const response = await postData(endpoint, gymGroupImages);
      return response.data;
    } catch (error) {
      console.error('Error saving gym group images:', error);
      throw error;
    }
  };

  // Function to delete a gym by ID
export const deleteById = async (gymId: number): Promise<void> => {
  try {
    const endpoint = `/api/gym/delete/${gymId}`; // Construct the endpoint with gymId
    await deleteData(endpoint); // Make the DELETE request using deleteData function
    console.log(`Gym with ID ${gymId} deleted successfully.`); // Log success message
  } catch (error) {
    console.error(`Error deleting gym with ID ${gymId}:`, error);
    throw error; // Throw the error to be handled by the caller
  }
};