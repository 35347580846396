import {postData} from './apiService';

export const getPaymentByCreatedBy = async (createdBy: string, page: number, size: number, sortOrders: any[], globalFilter: string, filters: any[]) => {
    try {
        const requestBody = {
            createdBy,
            filters,
            pageIndex: page,
            pageSize: size,
            sortOrders,
            globalFilter
        };

        console.log('Request Body:', JSON.stringify(requestBody));  // Log the request body

        const endpoint = '/api/payment/filter';
        const response = await postData(endpoint, requestBody);
        return response.data;
    } catch (error) {
        console.error("Error fetching payments:", error);
        throw error;
    }
};



