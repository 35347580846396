import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

interface Gym {
  id: number;
  image: string;
  images?: { imageUrl: string }[];
  name: string;
  description: string;
  location?: string;
}

interface GymCardProps {
  gym: Gym;
  onDelete: (id: number) => void;
}

const GymCard: React.FC<GymCardProps> = ({ gym, onDelete }) => {
  const navigate = useNavigate();

  // Slider settings for auto-play every 2 seconds
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // 2 seconds
  };

  return (
    <div className="bg-white rounded-lg shadow-lg w-full md:w-[300px] mb-6 p-6 text-center">
      {gym.images && gym.images.length > 1 ? (
        // Render slider if there's more than one image
        <Slider {...sliderSettings}>
          {gym.images.map((img, index) => (
            <img
              key={index}
              src={img.imageUrl}
              alt={`${gym.name} - ${index + 1}`}
              className="w-full h-36 object-cover rounded-lg"
            />
          ))}
        </Slider>
      ) : (
        // If only one image, display it directly
        <img
          src={gym.images && gym.images.length === 1 ? gym.images[0].imageUrl : gym.image}
          alt={gym.name}
          className="w-full h-36 object-cover rounded-lg"
        />
      )}
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <h3 className="font-bold text-lg">{gym.name}</h3>
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={() => onDelete(gym.id)}
            className="text-red-500 cursor-pointer hover:opacity-80"
          />
        </div>
        <p className="mt-2 text-gray-600">{gym.description}</p>
        <p className="mt-2 text-gray-500">
          <span role="img" aria-label="location">📍</span> {gym.location}
        </p>
      </div>
      <div className="mt-4 flex justify-between">
        <button
          className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 w-1/2 mr-2"
          onClick={() => navigate('/gym-branch', { state: { gymId: gym.id, gymName: gym.name } })}
        >
          View
        </button>
        <button
          onClick={() => navigate('/add-gym-business', { state: { gymId: gym.id } })}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-1/2"
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default GymCard;
