import React, { useState, ChangeEvent, FormEvent, useCallback,useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import keycloak from '../config/keycloakConfig';
import { getMasterDataByUserId } from '../services/masterDataService';
import { createUserSubscription,updateUserSubscription } from '../services/userSubscriptionService';
import Select from 'react-select';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; 




interface FormData {
  userName: string;
  email: string;
  phoneNumber: string;
  gym: string;
  branch: string;
  subscriptionPlan: string;
  gender: string;
}

// Interface for the fetched gym data
interface GymGroup {
  id: number;
  gymGroupName: string;
  description: string;
  country: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: number[];
  updatedAt: number[] | null;
  userId: string;
  gyms: Gym[] | null;
  images: any[] | null;
}

interface Gym {
  id: number;
  gymName: string;
  gymType: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  branchBankName: string;
  swiftCode: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: number[];
  updatedAt: number[] | null;
  gymHours: GymHour[] | null;
  images: any[] | null;
  subscriptions: any[] | null;
  geoLocation: string;
  gymGroupId: number;
}

interface GymHour {
  id: number;
  day: string;
  openTime: number[];
  closeTime: number[];
  gymId: number;
  createdBy: string;
  updatedBy: string | null;
  createdAt: number[];
  updatedAt: number[] | null;
  offDay: boolean;
}

interface GymData {
  gymGroup: GymGroup;
  gyms: Gym[];
  gymHours: GymHour[];
  subscriptionPlans: { id: number; gymId:number; subscriptionName: string; description: string; price: number; duration: number; }[];
}



interface Errors {
  userName?: string;
  email?: string;
  phoneNumber?: string;
}

interface Touched {
  userName: boolean;
  email: boolean;
  phoneNumber: boolean;
}

function UserForm() {
  const [formData, setFormData] = useState<FormData>({
    userName: '',
    email: '',
    phoneNumber: '',
    gym:'',
    branch:'',
    subscriptionPlan:'',
    gender:''
  });


  const navigate = useNavigate();
  const location = useLocation();
  const [filteredBranches, setFilteredBranches] = useState<Gym[]>([]);
  const [filteredPlans, setFilteredPlans] = useState<{ id: number; subscriptionName: string; description: string; price: number; duration: number; }[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState<Errors>({});
  const subscription = location.state?.subscription;
  const subscriptionId = location.state?.subscription.id;


  // Replace 'any' with the actual type if needed
  const [data, setData] = useState<GymData[] | null>(null);
  

  const [touched, setTouched] = useState<Touched>({
    userName: false,
    email: false,
    phoneNumber: false
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [SubscriptionData, setSubscriptionData] = useState<FormData>({
    userName: '',
    email: '',
    phoneNumber: '',
    gym: '',
    branch: '',
    subscriptionPlan: '',
    gender:''
  });

  useEffect(() => {
    if (subscription) {
      setFormData({
        userName: subscription.userName || '',
        email: subscription.email || '',
        phoneNumber: subscription.phone || '',
        gym: subscription.gymGroupId || '',
        branch: subscription.gymId || '',
        subscriptionPlan: subscription.subscriptionId || '',
        gender:subscription.gender || ''
      });
  
      // Find the selected gym group
      const selectedGymGroup = data?.find(
        (group) => group.gymGroup.id.toString() === subscription.gymGroupId?.toString()
      );
      setFilteredBranches(selectedGymGroup ? selectedGymGroup.gyms : []);
  
      // Find the selected branch
      const selectedBranch = selectedGymGroup?.gyms?.find(
        (branch) => branch.id.toString() === subscription.gymId?.toString()
      );
  
      // Filter subscription plans for the selected branch
      const allPlans = data?.flatMap((group) => group.subscriptionPlans) || [];
      const plans = allPlans.filter((plan) =>
        selectedBranch ? plan.gymId === selectedBranch.id : false
      );
  
      setFilteredPlans(plans);

    }
  }, [subscription, data]);
  
  

  useEffect(() => {
    const fetchData = async () => {
      const userId = keycloak.tokenParsed?.sub;

      if (!userId) {
        setError('User ID is not available.');
        return;
      }

      try {
        const result = await getMasterDataByUserId(userId);
        setData(result); // Update state with the fetched data
      } catch (err) {
        setError('Failed to fetch data.');
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [keycloak]);
  


  const validate = useCallback((data: FormData) => {
    const newErrors: Errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (!data.userName.trim()) newErrors.userName = 'User Name is required';
    if (!emailRegex.test(data.email)) newErrors.email = 'Invalid email format';
    if (!phoneRegex.test(data.phoneNumber)) newErrors.phoneNumber = 'Phone Number must be 10 digits';

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, []);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | { name: string; value: string }
  ) => {
    if ('target' in e) {
      // For regular input and select changes
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
      validate({ ...formData, [name]: value });
  
      if (!data) {
        console.error('Data is null');
        return; // Early return to prevent errors when data is null
      }
  
      if (name === 'gym') {
        const selectedGymGroup = data.find((group) => group.gymGroup.id.toString() === value);
        console.log('Selected Gym Group:', selectedGymGroup); // Debugging line
        setFilteredBranches(selectedGymGroup ? selectedGymGroup.gyms : []);
        setFormData((prev) => ({ ...prev, branch: '', subscriptionPlan: '' })); // Reset branch and plan
        setFilteredPlans([]); // Reset plans when gym changes
      }
  
      if (name === 'branch') {
        const selectedBranch = filteredBranches.find((branch) => branch.id.toString() === value);
        console.log('Selected Branch:', selectedBranch); // Debugging line
  
        // Collect all subscription plans across all gym data entries and filter by the selected branch's gymId
        const allPlans = data.flatMap((group) => group.subscriptionPlans);
        const plans = allPlans.filter((plan) => plan.gymId === selectedBranch?.id);
        console.log('Filtered Plans:', plans); // Debugging line for plans
  
        setFilteredPlans(plans);
        setFormData((prev) => ({ ...prev, subscriptionPlan: '' })); // Reset plan
      }
    } else {
      // For custom updates like PhoneInput
      const { name, value } = e;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
      validate({ ...formData, [name]: value });
    }
  };
  
  
  const handleSubmit = async (e: FormEvent) => {
    const userId = keycloak.tokenParsed?.sub ?? 'system';
    e.preventDefault();
    validate(formData);
  
    if (isFormValid) {
      try {
        // Find the names based on the selected IDs
        const gymGroupName = data?.find(group => group.gymGroup.id === parseInt(formData.gym))?.gymGroup.gymGroupName ?? '';
        const branchName = filteredBranches?.find(branch => branch.id === parseInt(formData.branch))?.gymName ?? '';
        const subscriptionName = filteredPlans?.find(plan => plan.id === parseInt(formData.subscriptionPlan))?.subscriptionName ?? '';
  
        // Prepare the data to match the API request structure
        const subscriptionData = {
          gymGroupId: parseInt(formData.gym), // Convert to number if necessary
          gymId: parseInt(formData.branch), // Convert to number if necessary
          subscriptionId: parseInt(formData.subscriptionPlan), // Convert to number if necessary
          gymName: branchName,
          gymGroupName: gymGroupName,
          subscriptionName: subscriptionName,
          userName: formData.userName,
          email: formData.email,
          phone: formData.phoneNumber,
          gender: formData.gender,
          createdBy: userId, // Used for create
          updatedBy: '', // Used for update
        };
  
        let response;
  
        if (subscriptionId) {
          // Update subscription if `subscription` exists
          subscriptionData.updatedBy = userId; // Set the `updatedBy` field
          response = await updateUserSubscription(subscriptionData, subscription.id); // Pass subscription ID for update
          Swal.fire('Success!', 'User subscription updated successfully', 'success');
        } else {
          // Create subscription if `subscription` doesn't exist
          response = await createUserSubscription(subscriptionData);
          Swal.fire('Success!', 'User subscription created successfully', 'success');
        }
  
        console.log('API Response:', response);
  
        // Reset form after submission
        setFormData({
          userName: '',
          email: '',
          phoneNumber: '',
          gym: '',
          branch: '',
          subscriptionPlan: '',
          gender:''
        });
        setTouched({ userName: false, email: false, phoneNumber: false }); // Reset touched state
        navigate('/subscription-user');
      } catch (error) {
        console.error('Error handling subscription:', error);
        Swal.fire('Error!', 'An error occurred while submitting the form. Please try again.', 'error');
      }
    } else {
      Swal.fire('Error!', 'Please fix the form errors before submitting', 'error');
    }
  };
  
  

  const handleCancel = () => {
    // Reset the form fields when Cancel is clicked
    setFormData({ userName: '', email: '', phoneNumber: '', gym:'',branch:'',subscriptionPlan:'',gender:'' });
    setTouched({ userName: false, email: false, phoneNumber: false }); // Reset touched state
    navigate('/subscription-user');
  };

  return (
    <form onSubmit={handleSubmit} style={{ backgroundColor:'white', margin: '0 auto', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
      <h2 className="text-2xl font-bold mb-6">{'NEW USER SUBSCRIPTION'}</h2>
      <div style={{ marginBottom: '1rem' }}>
        <label htmlFor="userName" style={{ display: 'block', marginBottom: '.5rem' }}>User Name<span className="text-red-500">*</span></label>
        <input
          type="text"
          id="userName"
          name="userName"
          value={formData.userName}
          onChange={handleChange}
          style={{ border:'1px solid black', borderRadius:'5px', width: '100%', padding: '.5rem', borderColor: errors.userName && touched.userName ? 'red' : '' }}
          required
        />
        {touched.userName && errors.userName && <p style={{ color: 'red', marginTop: '.25rem' }}>{errors.userName}</p>}
      </div>

      <div style={{ marginBottom: '1rem' }}>
  <label htmlFor="email" style={{ display: 'block', marginBottom: '.5rem' }}>
    Email<span className="text-red-500">*</span>
  </label>
  <input
    type="email"
    id="email"
    name="email"
    value={formData.email}
    onChange={handleChange}
    onInput={(e) => {
      const target = e.target as HTMLInputElement;
      const validCharactersRegex = /^[a-zA-Z0-9@._-]*$/;
      target.value = target.value.split('').filter((char) => validCharactersRegex.test(char)).join('');
    }}
    style={{
      border: '1px solid black',
      borderRadius: '5px',
      width: '100%',
      padding: '.5rem',
      borderColor: errors.email && touched.email ? 'red' : '',
    }}
    required
  />
  {touched.email && errors.email && (
    <p style={{ color: 'red', marginTop: '.25rem' }}>{errors.email}</p>
  )}
</div>


      <div style={{ marginBottom: '1rem' }}>
        <label htmlFor="phoneNumber" style={{ display: 'block', marginBottom: '.5rem' }}>
          Phone Number<span className="text-red-500">*</span>
        </label>
        <PhoneInput
          country={'sa'}
          value={formData.phoneNumber}
          onChange={(value) => handleChange({ name: 'phoneNumber', value })}
          inputStyle={{
            border: '1px solid black',
            borderRadius: '5px',
            width: '100%',
            height:'40px',
            padding: '.5rem',
            marginLeft: '2.5rem', // Ensures flag and input have spacing
            borderColor: errors.phoneNumber && touched.phoneNumber ? 'red' : '',
          }}
          containerStyle={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem', // Adds space between the flag and the input
          }}
          inputProps={{
            id: 'phoneNumber',
            name: 'phoneNumber',
            required: true,
          }}
        />


  {touched.phoneNumber && errors.phoneNumber && (
    <p style={{ color: 'red', marginTop: '.25rem' }}>{errors.phoneNumber}</p>
  )}
      </div>

        {/* Other Form Fields */}
  
  <div style={{ marginBottom: '1rem' }}>
    <label htmlFor="gender" style={{ display: 'block', marginBottom: '.5rem' }}>Gender<span className="text-red-500">*</span></label>
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <label style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
        <input
          type="radio"
          id="genderMale"
          name="gender"
          value="MALE"
          checked={formData.gender === 'MALE'}
          onChange={handleChange}
          required
          style={{ cursor: 'pointer' }}
        />
        <span>Men</span>
      </label>
      <label style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
        <input
          type="radio"
          id="genderFemale"
          name="gender"
          value="FEMALE"
          checked={formData.gender === 'FEMALE'}
          onChange={handleChange}
          required
          style={{ cursor: 'pointer' }}
        />
        <span>Women</span>
      </label>
    </div>
  </div>

      {/* New row with three select option input fields */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label htmlFor="gym" style={{ display: 'block', marginBottom: '.5rem' }}>Gym<span className="text-red-500">*</span></label>
          <select
            id="gym"
            name="gym"
            value={formData.gym}
            onChange={handleChange}
            style={{ border: '1px solid black', borderRadius: '5px', width: '100%', padding: '.5rem' }}
            required
          >
         <option value="">Select a gym group</option>
        {data?.map(group => (
          <option key={group.gymGroup.id} value={group.gymGroup.id}>{group.gymGroup.gymGroupName}</option>
        ))}
          </select>
        </div>

        <div style={{ flex: 1 }}>
          <label htmlFor="branch" style={{ display: 'block', marginBottom: '.5rem' }}>Branch<span className="text-red-500">*</span></label>
          <select
            id="branch"
            name="branch"
            value={formData.branch}
            onChange={handleChange}
            style={{ border: '1px solid black', borderRadius: '5px', width: '100%', padding: '.5rem' }}
            required
          >
        <option value="">Select a branch</option>
        {filteredBranches.map(branch => (
          <option key={branch.id} value={branch.id}>{branch.gymName}</option>
        ))}
          </select>
        </div>

        <div style={{ flex: 1 }}>
          <label htmlFor="subscriptionPlan" style={{ display: 'block', marginBottom: '.5rem' }}>Subscription Plan<span className="text-red-500">*</span></label>
          <select
            id="subscriptionPlan"
            name="subscriptionPlan"
            value={formData.subscriptionPlan}
            onChange={handleChange}
            style={{ border: '1px solid black', borderRadius: '5px', width: '100%', padding: '.5rem' }}
            required
          >
        <option value="">Select a subscription plan</option>
        {filteredPlans.map(plan => (
          <option key={plan.id} value={plan.id}>{plan.subscriptionName}</option>
        ))}
          </select>
        </div>
      </div>

      {/* Button container */}
      <div style={{ display: 'flex', marginTop: '2rem', justifyContent: 'flex-end' }}>
        <button
          type="submit"
          style={{ padding: '.5rem 1rem', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
        >
          {subscription ? 'Update' : 'Submit'} 
        </button>
        <button
          type="button"
          onClick={handleCancel}
          style={{ marginLeft: '5px', padding: '.5rem 1rem', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
        >
          Cancel
        </button>
      </div>

    </form>
  );
}

export default UserForm;
