import React from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faCog, faSignOutAlt, faBars, faUsers } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import logo from '../assets/logo.png';
import keycloak from '../config/keycloakConfig'; 

// Define the types for props
interface SidebarProps {
  isCollapsed: boolean;
  handleToggle: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, handleToggle }) => {

  // Handle user logout
  const handleLogout = () => {
    keycloak.logout({
      redirectUri: window.location.origin // Redirect back to the login page after logout
    });
  };

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/add-gym');
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      {/* Toggle Button */}
      <div className="top-icon" onClick={handleToggle}>
        <FontAwesomeIcon icon={faBars} className="menu-icon" />
      </div>

      {/* Logo */}
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        {<img src={logo} alt="MyCity Logo" className="logo-image" />}
      </div>

      {/* Navigation Menu */}
      <nav className="background">
        <ul>
          <li>
            <Link to="/add-gym">
              <FontAwesomeIcon icon={faList} className="menu-item-icon"/>
              {!isCollapsed && 'List Gym'}
            </Link>
          </li>
          <li>
            <Link to="/subscription-user">
              <FontAwesomeIcon icon={faUsers} className="menu-item-icon"/>
              {!isCollapsed && 'List Users'}
            </Link>
          </li>
          <li>
            <Link to="/payments">
              <FontAwesomeIcon icon={faUsers} className="menu-item-icon"/>
              {!isCollapsed && 'Payments'}
            </Link>
          </li>
          {/* Add more menu items as needed */}
        </ul>
      </nav>

      {/* Settings and Log out */}
      <div className="sidebar-bottom">
        <ul className="nav-bottom-links">
          <li>
            <Link to="/settings">
              <FontAwesomeIcon icon={faCog} className="menu-item-icon" />
              {!isCollapsed && 'Settings'}
            </Link>
          </li>
          <li>
            <Link to="/logout" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} className="menu-item-icon" />
              {!isCollapsed && 'Log out'}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
