import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { faBell,faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import profilePicture from '../assets/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import './GymCard.css';
import './GymBranch.css';
import { getSubscriptionByGymId, deleteSubscription } from '../services/subscription';

// Define the type for the subscription object
interface Subscription {
  id: number;
  subscriptionName: any;
  price: number;
  duration: string;
  description: string;
}

const AddSubscription: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const gymId = location.state?.gymId;
  const gymName = location.state?.gymName;

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Number of items per page

  // Fetch subscriptions by gymId when component mounts
  useEffect(() => {
    if (gymId) {
      setLoading(true);
      getSubscriptionByGymId(gymId)
        .then((data) => {
          setSubscriptions(data as Subscription[]);
        })
        .catch((error) => {
          console.error('Error fetching subscriptions:', error);
        })
        .finally(() => setLoading(false));
    }
  }, [gymId]);

  const handleDelete = async (subscriptionId: number) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone. Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
  
    if (result.isConfirmed) {
      try {
        await deleteSubscription(subscriptionId);
        setSubscriptions(subscriptions.filter(sub => sub.id !== subscriptionId));
        Swal.fire('Deleted!', 'Subscription has been deleted successfully.', 'success');
      } catch (error) {
        setError('Failed to delete subscription. Please try again later.');
      }
    }
  };

  // Calculate the current page's data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSubscriptions = subscriptions.slice(indexOfFirstItem, indexOfLastItem);

  // Navigate to the next page
  const nextPage = () => {
    if (currentPage < Math.ceil(subscriptions.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Navigate to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(subscriptions.length / itemsPerPage);

  return (
    <React.Fragment>
      <div className="header">
      <FontAwesomeIcon icon={faArrowLeft} className="icon-arrow-left" onClick={() => navigate(-1)} />
        <input style={{ width: '650px'}} type="text" placeholder="Search" className="search-bar" />
        <div className="header-right">
          <button className="add-gym-button" onClick={() => navigate('/subscription-form', { state: { gymId, subscriptionNames: subscriptions.map((subscription) => subscription.subscriptionName), } })}>
            ADD SUBSCRIPTION
          </button>
          <FontAwesomeIcon icon={faBell} className="icon-bell" />
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
      </div>
  
      <div>
        <h2 className="flex justify-center text-2xl font-bold">Subscription Plans of {gymName}</h2>
      </div>
  
      {loading ? (
        <div className="text-center">Loading...</div>
      ) : subscriptions.length === 0 ? (
        <div className="text-center mt-4">No subscriptions available</div>
      ) : (
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full border-collapse bg-white">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="p-3 border-b-2">Plan Name</th>
                <th className="p-3 border-b-2">Duration</th>
                <th className="p-3 border-b-2">Price</th>
                <th className="p-3 border-b-2">Description</th>
                <th className="p-3 border-b-2 text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentSubscriptions.map((subscription) => (
                <tr key={subscription.id} className="border-b">
                  <td className="p-3">{subscription.subscriptionName}</td>
                  <td className="p-3">{subscription.duration}</td>
                  <td className="p-3">SAR {subscription.price}</td>
                  <td className="p-3">{subscription.description}</td>
                  <td className="p-3 text-right">
                    <button
                      className="text-blue-500 hover:text-blue-700 mr-2 border border-blue-500 px-3 py-1 rounded"
                      onClick={() => navigate('/subscription-form', { state: { subscriptionId: subscription.id, subscription, gymId } })}
                    >
                      Edit
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 border border-red-500 px-3 py-1 rounded"
                      onClick={() => handleDelete(subscription.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
  
          {/* Pagination Controls */}
          <div className="flex justify-end mt-4">
            {currentPage > 1 && (
              <button
                onClick={prevPage}
                className="px-4 py-1 mr-2 rounded bg-blue-500 text-white"
              >
                Previous
              </button>
            )}
            {currentPage < totalPages && (
              <button
                onClick={nextPage}
                className="px-4 py-1 rounded bg-blue-500 text-white"
              >
                Next
              </button>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );  
};

export default AddSubscription;
