// src/services/apiService.ts
import axios, { AxiosInstance, AxiosResponse } from 'axios';

// Create an Axios instance
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://api.mycitygym.evxtest.monster/admin', // Backend base URL
  // baseURL: 'http://localhost:8085/admin',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Optional: Add an interceptor for adding authentication tokens
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // Assuming you store the token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Define generic types for API responses
type ApiResponse<T> = Promise<AxiosResponse<T>>;

// API service functions
export const getData = async <T>(endpoint: string): ApiResponse<T> => {
  try {
    const response = await axiosInstance.get<T>(endpoint);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const postData = async <T>(endpoint: string, data: any): ApiResponse<T> => {
  try {
    const response = await axiosInstance.post<T>(endpoint, data);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const putData = async <T>(endpoint: string, data: any): ApiResponse<T> => {
  try {
    const response = await axiosInstance.put<T>(endpoint, data);
    return response;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

export const deleteData = async <T>(endpoint: string): ApiResponse<T> => {
  try {
    const response = await axiosInstance.delete<T>(endpoint);
    return response;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
