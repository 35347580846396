import { postData, getData, deleteData, putData } from './apiService';

// Interface for the request body
interface CreateUserSubscriptionPayload {
    gymGroupId: number;
    gymId: number;
    subscriptionId: number;
    userName: string;
    email: string;
    phone: string;
    createdBy: string;
    gender:string;
    updatedBy: string;
}

// Interface for the response from the API
interface CreateUserSubscriptionResponse {
    id: number; // Modify as needed based on the actual response structure
    // Add any other response fields as required
}

// Function to create a new subscription user
export const createUserSubscription = async (
    subscriptionData: CreateUserSubscriptionPayload
): Promise<CreateUserSubscriptionResponse> => {
    try {
        const endpoint = `/api/subscription-user/create`; // Ensure this matches your API
        const response = await postData(endpoint, subscriptionData);

        // Cast the response data to the CreateUserSubscriptionResponse type
        return response.data as CreateUserSubscriptionResponse;
    } catch (error) {
        console.error('Error creating subscription user:', error);
        throw error;
    }
};

// Function to update an existing Subscription
export const updateUserSubscription = async (SubscriptionData: CreateUserSubscriptionPayload, subscriptionId: number): Promise<CreateUserSubscriptionResponse> => {
    try {
        const endpoint = `/api/subscription-user/update/${subscriptionId}`; // Adjust the endpoint according to your API
        const response = await putData(endpoint, SubscriptionData); // Use postData for the update

        // Cast the response data to the CreateSubscriptionResponse type
        return response.data as CreateUserSubscriptionResponse;
    } catch (error) {
        console.error('Error updating subscription:', error);
        throw error;
    }
};

// Function to get subscription by userId
export const getSubscriptionByUserId = async (userId: string) => {
    try {
        const timestamp = new Date().getTime(); // Generate a unique timestamp
        const endpoint = `/api/subscription-user/get-by-created-by/${userId}?_=${timestamp}`; // Construct the endpoint
        const response = await getData(endpoint); // Make the GET request using getData function
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error fetching subscription by gymId:', error);
        throw error; // Throw the error to be handled by the caller
    }
};

// Function to delete a subscription by ID
export const deleteUserSubscription = async (subscriptionId: number) => {
    try {
        const endpoint = `/api/subscription-user/delete/${subscriptionId}`;
        const response = await deleteData(endpoint);
        return response.data; // Optionally return data or a success message
    } catch (error) {
        console.error('Error deleting subscription:', error);
        throw error;
    }
};

export const getSubscriptionUsersByCreatedBy = async (createdBy: string, page: number, size: number, sortOrders: any[], globalFilter: string, filters: any[]) => {
    try {
        const requestBody = {
            createdBy,
            filters,
            pageIndex: page,
            pageSize: size,
            sortOrders,
            globalFilter
        };

        console.log('Request Body:', JSON.stringify(requestBody));  // Log the request body

        const endpoint = '/api/subscription-user/filter';
        const response = await postData(endpoint, requestBody);
        return response.data;
    } catch (error) {
        console.error("Error fetching payments:", error);
        throw error;
    }
};